import { tuz } from './Insurers';

export const insurersWithPromotions = [
  tuz,
];

export const promotions = {
  [tuz]: {
    text: 'U nas zniżka 10%',
    tooltip: 'Zniżka jest naliczona automatycznie przy przejściu na formularz TUZ.',
  },
};
