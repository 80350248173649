import { priceFormatter } from 'porowneo-ui';

export const priceForCover = (price, cover) => {
  switch (cover) {
  case 'animalBites':
  case 'misadventure':
  case 'damageToHealth':
  case 'hospitalTreatment':
  case 'permanentInjury':
  case 'rehabilitationCosts':
  case 'treatmentCosts':
    if (typeof price === 'number') {
      return `do ${priceFormatter(price, true)}`;
    }
    return price;
  case 'hospitalDay':
    return `${priceFormatter(price, true, 'zł / dzień')}`;
  default:
    return price;
  }
};
