export const getDisplayName = WrappedComponent => WrappedComponent?.displayName || WrappedComponent?.name || 'Component';

export const translateCovers = (cover) => {
  if (typeof cover === 'string' && cover.length > 0) {
    switch (cover) {
    case 'misadventure':
    case 'misadventureFilter':
      return 'Nieszczęśliwy wypadek';
    case 'damageToHealth':
    case 'damageToHealthFilter':
      return 'Uszczerbek na zdrowiu (1%)';
    case 'hospitalTreatment':
    case 'hospitalTreatmentFilter':
      return 'Leczenie szpitalne';
    case 'hospitalDay':
    case 'hospitalDayFilter':
      return 'Pobyt w szpitalu za każdy dzień';
    case 'totalFilterFilter':
    case 'totalFilter':
      return 'Składka';
    case 'permanentInjuryFilter':
    case 'permanentInjury':
      return 'Trwały uszczerbek na zdrowiu';
    case 'rehabilitationCostsFilter':
    case 'rehabilitationCosts':
      return 'Koszty rehabilitacji';
    case 'treatmentCostsFilter':
    case 'treatmentCosts':
      return 'Koszty leczenia';
    case 'animalBitesFilter':
    case 'animalBites':
      return 'Pogryzienie przez zwierzęta';
    default:
      return cover.toUpperCase();
    }
  } else {
    return cover;
  }
};

export const randomLocator = () => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ';
  let randomString = '';
  for (let i = 0; i < 6; i += 1) {
    const rNum = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(rNum, rNum + 1);
  }
  return randomString;
};

export const getBaseName = (currentPath, baseNames) => {
  let newBasName = '/';

  if (Array.isArray(baseNames) && baseNames.length > 0) {
    baseNames.forEach((baseName) => {
      if (currentPath.startsWith(baseName)) {
        newBasName = baseName;
      }
    });
  }

  return newBasName;
};

export const setOrder = ({ order, orderType }) => {
  switch (orderType) {
  case 'asc':
    if (order === 0 || typeof order === 'string') {
      return 999999;
    }
    return order || 999999;
  case 'desc':
    if (order === 0 || typeof order === 'string') {
      return 0;
    }
    return order || 0;
  default:
    return order || 999999;
  }
};
