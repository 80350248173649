import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { createStore, applyMiddleware, compose } from 'redux';
import { ThemeProvider } from 'styled-components';
import log from 'loglevel';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';

import rootReducer from './reducers/rootReducer';
import { loadState, saveState } from './helpers/store/storeUtils';
import GlobalStyle from './styled/GlobalStyle';
import { gridTheme } from './styled/gridTheme';
import theme from './styled/theme';
import AppContainer from './containers/App';

require('dotenv').config();

const middleware = [];

if (process.env.NODE_ENV !== 'production') {
  log.enableAll();
} else {
  log.disableAll();
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middleware)),
);

store.subscribe(() => {
  saveState(store.getState());
});

const rootElement = document.getElementById('root');

const WrappedApp = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BaseCSS />
      <GlobalStyle />
      <GridThemeProvider
        gridTheme={gridTheme}
      >
        <AppContainer />
      </GridThemeProvider>
    </ThemeProvider>
  </Provider>
);

render(
  <WrappedApp />,
  rootElement,
);
