import React from 'react';

import PropTypes from 'prop-types';

import {
  SortItemsStyled,
  SortItemsContainer,
  SortButton,
  SortRow,
  SortCol,
  PageTitle,
} from '../../styled/components/SortItems.styled';
import { translateCovers } from '../../helpers/utils';

const setOrder = (key) => {
  switch (key) {
  case 'misadventureFilter':
    return 1;
  case 'damageToHealthFilter':
    return 2;
  case 'hospitalTreatmentFilter':
    return 3;
  case 'hospitalDayFilter':
    return 4;
  default:
    return 999;
  }
};

const SortItems = (props) => {
  const {
    handleSort,
    sortBy,
    pageHeader,
  } = props;

  return (
    <SortItemsStyled>
      <SortItemsContainer>
        <SortRow
          header
        >
          <SortCol
            col
            header
          >
            <PageTitle>
              {pageHeader}
            </PageTitle>
          </SortCol>
        </SortRow>
        <SortRow>
          <SortCol
            wrapElements
            col={12}
          >
            {Object.keys(sortBy).map(key => (
              <SortButton
                order={setOrder(key)}
                key={`sortBy-${key}`}
                onClick={() => handleSort(key, sortBy[key])}
                active={sortBy[key].active}
                sort={sortBy[key].sort}
              >
                {translateCovers(key)}
              </SortButton>
            ))}
          </SortCol>
        </SortRow>
      </SortItemsContainer>
    </SortItemsStyled>
  );
};

SortItems.defaultProps = {
  pageHeader: 'Porównaj oferty ubezpieczeń NNW szkolnego',
};

SortItems.propTypes = {
  handleSort: PropTypes.func.isRequired,
  sortBy: PropTypes.shape({
    active: PropTypes.bool,
    sort: PropTypes.string,
  }).isRequired,
  pageHeader: PropTypes.string,
};

export default SortItems;
