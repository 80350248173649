/* eslint-disable no-alert */
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Header, FooterV2 as Footer } from 'porowneo-ui';
import styled from 'styled-components';

import { BASE_NAME } from '../config/url';
import { isRadioZetTheme } from '../config/main';
import { getBaseName } from '../helpers/utils';

import Results from './Results';
import Redirect from './Redirect';

export const AppWrapper = styled.div`
  font-family: ${props => props.theme.font.fontFamily};
`;

export const App = (props) => {
  const {
    locator,
  } = props;

  return (
    <AppWrapper>
      <Router basename={getBaseName(window.location.pathname, BASE_NAME)}>
        <Header
          locator={locator}
          hiddenTitle
          locatorMargin
          logoLink="https://porowneo.pl/"
          logoLinkTarget="_blank"
        />

        <Switch>
          <Route
            path="/redirect"
            render={() => (
              <Redirect />
            )}
          />
          <Route component={Results} />

        </Switch>

        {
          !isRadioZetTheme && (
            <Footer />
          )
        }
      </Router>
    </AppWrapper>
  );
};

const mapStateToProps = ({ payload }) => ({
  step: payload.step,
  locator: payload.locator,
  ...payload,
});

export default connect(mapStateToProps)(App);
