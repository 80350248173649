// Routes
export const BASE_NAME = process?.env?.['REACT_APP_BASE_NAME'] ?? '/';

// Configs
export const axiosConfigAccountForm = () => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/javascript, */*; q=0.01',
  },
});

export const axiosConfigJson = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
};
