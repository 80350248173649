import React from 'react';

import PropTypes from 'prop-types';
import { CustomTooltip } from 'porowneo-ui';

import { PromotionContainer, PromotionTitle } from '../../styled/components/Promotion.styled';

export const Promotion = ({ title, tooltipMsg }) => (
  <PromotionContainer
    data-testid="promo-container"
  >
    <PromotionTitle>
      {title}
    </PromotionTitle>
    {tooltipMsg && (
      <div
        data-testid="promo-tooltip"
      >
        <CustomTooltip
          message={tooltipMsg}
          isTooltipWithoutFooter
        />
      </div>
    )}
  </PromotionContainer>
);

Promotion.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipMsg: PropTypes.string.isRequired,
};
