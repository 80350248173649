import React, { useEffect } from 'react';

import styled from 'styled-components';

const Wrappers = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  z-index: 10000;
  background: white;
  display: block;
`;

const Redirect = () => {
  useEffect(() => {
    const url = document.location.href;
    const urlTo = url.split('?url=')[1];

    setTimeout(() => {
      window.location.href = urlTo ? decodeURIComponent(urlTo) : '/';
    }, 600);
  }, []);

  return <Wrappers className="white" />;
};

export default Redirect;
