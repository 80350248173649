import styled from 'styled-components';
import { torem } from 'porowneo-ui';
import {
  Col, media,
} from 'styled-bootstrap-grid';

export const PromotionContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.md`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

PromotionContainer.displayName = 'PromotionContainer';

export const PromotionTitle = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: ${torem(12)};
  font-family: ${props => props.theme.font.fontFamily};
  color: ${props => props.theme.colors.primary};
  margin: 15px 0 10px;

`;

PromotionTitle.displayName = 'PromotionTitle';
