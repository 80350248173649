import { cloneDeep } from 'lodash';
import log from 'loglevel';

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (error) {
    log.debug(error);
    return {};
  }
};

export const saveState = (state) => {
  try {
    const stateToStore = cloneDeep(state);
    const serializedState = JSON.stringify(stateToStore);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    log.debug(error);
  }
};
