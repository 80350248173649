export default {
  permanentInjuryFilter: {
    active: false,
    sort: 'asc',
  },
  rehabilitationCostsFilter: {
    active: false,
    sort: 'asc',
  },
  treatmentCostsFilter: {
    active: false,
    sort: 'asc',
  },
  animalBitesFilter: {
    active: false,
    sort: 'asc',
  },
  totalFilter: {
    active: false,
    sort: 'asc',
  },
};
