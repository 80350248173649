import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  ResultsContainer,
  ResultsStyled,
  ResultsRow,
  ResultsCol,
  ResultTitle,
  ResultsExtra,
  ResultsSortContainer,
} from '../styled/containers/Results.styled';
import ResultsItem from '../components/results/ResultsItem';
import SortItems from '../components/results/SortItems';
import wsResults from '../json/insuranceOffers.json';
import { resultHeader, resultExtraDesc } from '../config/companyConfig';
import filters from '../config/filters';
import { pushToDataLayer } from '../helpers/AnalityicsUtils';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sorted: filters,
      actualSort: {
        sortedBy: '',
        sort: 'asc',
      },
    };
  }

  handleSetSort = (key, sortBy) => {
    const {
      state: {
        sorted,
      },
    } = this;
    const changeSort = (sortType) => {
      if (sortBy.active) {
        switch (sortType) {
        case 'asc':
          return 'desc';
        default:
          return 'asc';
        }
      } else {
        return sortType;
      }
    };

    const newState = {
      ...sorted,
    };
    Object.keys(newState).forEach((sortedKey) => {
      if (key !== sortedKey) {
        newState[sortedKey] = {
          ...sorted[sortedKey],
          active: false,
        };
      }
    });

    const newSort = changeSort(sortBy.sort);

    this.setState({
      sorted: {
        ...newState,
        [key]: {
          sort: newSort,
          active: true,
        },
      },
      actualSort: {
        sortedBy: key,
        sort: newSort,
      },
    });
  };

  handleClickBuyOnline = (partnerName, partnerLink) => {
    pushToDataLayer(partnerName);
    window.open(partnerLink);
  };

  findByFilterName = filterType => filterType.replace('Filter', '');

  render() {
    const {
      state: {
        sorted,
        actualSort,
      },
      props: {
        resultsTitle,
      },
      handleSetSort,
      handleClickBuyOnline,
      findByFilterName,
    } = this;

    return (
      <>
        <SortItems
          sortBy={sorted}
          handleSort={handleSetSort}
          pageHeader={resultHeader}
        />
        <ResultsStyled>
          <ResultsContainer>
            <ResultsRow
              header
            >
              <ResultsCol
                col
              >
                <ResultTitle>
                  {resultsTitle}
                </ResultTitle>
              </ResultsCol>
            </ResultsRow>
            <ResultsSortContainer
              sort={actualSort.sort}
            >
              {wsResults && wsResults.length > 0 && (
                wsResults.map((item, index) => {
                  const sortItem = {
                    ...item.details,
                    total: item.total || 0,
                  };
                  return (
                    <ResultsItem
                      key={`result-${item.partner_name}`}
                      partnerName={item.partner_name}
                      covers={item.covers}
                      coverDetails={item.details}
                      additionalDesc={item.otherDesc}
                      optionalCovers={item.optionalCovers}
                      redirectLink={item.redirectLink}
                      handleClickBuyOnline={handleClickBuyOnline}
                      setOrder={sortItem[findByFilterName(actualSort.sortedBy)] || wsResults.length - index}
                      orderType={actualSort.sort}
                      total={item.total}
                    />
                  );
                })
              )}
            </ResultsSortContainer>
            <ResultsRow
              noBackground
              noMarginBottom
            >
              <ResultsCol
                col
              >
                <ResultsExtra>
                  {resultExtraDesc}
                </ResultsExtra>
              </ResultsCol>
            </ResultsRow>
          </ResultsContainer>
        </ResultsStyled>
      </>
    );
  }
}

Results.defaultProps = {
  resultsTitle: 'OFERTA',
};

Results.propTypes = {
  resultsTitle: PropTypes.string,
};
export default Results;
