import React from 'react';

import PropTypes from 'prop-types';
import { PartnerLogo } from 'porowneo-ui';

import { translateCovers } from '../../helpers/utils';
import {
  ResultsRow,
  ResultsCol,
  ResultButton,
  CheckedMark,
  CoverName,
  CoverDesc,
  Cover,
  CoverValue,
  ExtraDesc,
} from '../../styled/containers/Results.styled';
import { insurersWithPromotions, promotions } from '../../config/Promotions';

import { priceForCover } from './utils/ResultsUtils';
import { Promotion } from './Promotion';

const ResultsItem = (props) => {
  const {
    additionalDesc,
    covers,
    coverDetails,
    optionalCovers,
    redirectLink,
    partnerName,
    setOrder,
    orderType,
    handleClickBuyOnline,
    total,
  } = props;

  return (
    <ResultsRow
      order={setOrder}
      orderType={orderType}
      resultRow
    >
      <ResultsCol
        extraPadding
        paddingLeft
        md={2}
      >
        <PartnerLogo
          partnerName={partnerName}
          maxWidth="120px"
          maxHeight="60px"
          maxMobileWidth="90px"
          maxMobileHeight="42px"
        />
      </ResultsCol>
      <ResultsCol
        extraPadding
        md={6}
        lg={7}
      >
        <ResultsRow
          covers
        >
          {Object.keys(covers).map(cover => (
            <ResultsCol
              key={`${cover}-${partnerName}`}
              covers
              col={12}
              md={6}
            >
              {cover && coverDetails[cover] && (
                <Cover>
                  <CheckedMark />
                  <CoverDesc>
                    <CoverName>
                      {translateCovers(cover)}
                    </CoverName>
                    <CoverValue>
                      <span>
                        {`${priceForCover(coverDetails[cover], cover)}`}
                      </span>
                    </CoverValue>
                  </CoverDesc>
                </Cover>
              )}
            </ResultsCol>
          ))}
        </ResultsRow>
      </ResultsCol>

      <ResultsCol
        md={2}
        lg={1}
        covers
      >
        {total && (
          <Cover
            data-testid="total-price"
          >
            <CoverDesc
              mobilePadding
            >
              <CoverName
                wrapText
              >
                Składka
              </CoverName>
              <CoverValue>
                <span>
                  {`od ${total} zł`}
                </span>
              </CoverValue>
            </CoverDesc>
          </Cover>
        )}
      </ResultsCol>
      <ResultsCol
        extraPadding
        paddingRight
        md={2}
      >
        <ResultButton
          id={`buyButton-${partnerName}`}
          buttonPrimary
          handleClick={() => handleClickBuyOnline(partnerName, redirectLink)}
          isPromo={insurersWithPromotions.includes(partnerName)}
        >
          Sprawdź ofertę
        </ResultButton>
        {insurersWithPromotions.includes(partnerName) &&
          promotions[partnerName].text && (
          <Promotion
            title={promotions[partnerName].text}
            tooltipMsg={promotions[partnerName].tooltip}
          />
        )}
      </ResultsCol>
      <ResultsCol
        border
        col
      >
        {(additionalDesc || optionalCovers) && (
          <ResultsCol
            data-testid="additional-info"
          >
            {additionalDesc && (
              <ExtraDesc
                data-testid="additional-desc"
              >
                {`Dodatkowo: ${additionalDesc}`}
              </ExtraDesc>
            )}
            {optionalCovers && (
              <ExtraDesc
                data-testid="additional-options"
              >
                {`Opcjonalnie: ${optionalCovers}`}
              </ExtraDesc>
            )}
          </ResultsCol>
        )}
      </ResultsCol>
    </ResultsRow>
  );
};

ResultsItem.defaultProps = {
  partnerName: 'porowneo',
  covers: {
    misadventure: false,
    damageToHealth: false,
    hospitalTreatment: false,
    hospitalDay: false,
  },
  coverDetails: {
    misadventure: 'porowneo',
    damageToHealth: 'porowneo',
    hospitalTreatment: 'porowneo',
    hospitalDay: 'porowneo',
  },
  additionalDesc: '',
  optionalCovers: '',
  redirectLink: 'https://porowneo.pl/',
  setOrder: 1,
  orderType: 'asc',
  total: '',
};

ResultsItem.propTypes = {
  partnerName: PropTypes.string,
  covers: PropTypes.shape({
    misadventure: PropTypes.bool,
    damageToHealth: PropTypes.bool,
    hospitalTreatment: PropTypes.bool,
    hospitalDay: PropTypes.bool,
  }),
  coverDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  additionalDesc: PropTypes.string,
  optionalCovers: PropTypes.string,
  redirectLink: PropTypes.string,
  total: PropTypes.string,
  setOrder: PropTypes.number,
  orderType: PropTypes.string,
  handleClickBuyOnline: PropTypes.func.isRequired,
};

export default ResultsItem;
